.gallery_wrapper {
  position: relative;
  /* padding: 3rem 0; */
  font-weight: 500;
  color: #555;
  background-color: #feeeed;
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid rgba(207, 179, 179, 0.1);
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../images/gallery/gallery.jpg);
  background-repeat: no-repeat;
  height: 80vh;
  color: #fff;
  display: flex;
  align-items: center;
}

.gallery_card_items {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.gallery_card_item {
  width: calc(100% / 4);
}

.card_thumb {
  margin-right: 10px;
  cursor: pointer;
}

.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.sliderWrap .btnClose,
.sliderWrap .btnPrev,
.sliderWrap .btnNext {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  color: #fff;
  z-index: 9999;
}

.btnNext:hover,
.btnClose:hover,
.btnPrev:hover {
  opacity: 1;
}

.sliderWrap .btnClose {
  top: 40px;
  right: 40px;
}

.sliderWrap .btnPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}

.sliderWrap .btnNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.fullScreenImage {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullScreenImage img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  user-select: none;
}

@media (max-width: 565px) {
  .gallery_card_items {
    display: block;
  }

  .gallery_card_item {
    width: calc(100% / 1);
    margin-bottom: 20px;
  }

  .card_thumb {
    margin-right: 0px;
  }
}
