/* .mbbs_section {
  position: relative; */
/* padding: 3rem 0; */
/* font-weight: 500;
  color: #555;
  background-color: #feeeed;
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid rgba(207, 179, 179, 0.1);
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../images/banner/mbbs.jpg);
  background-repeat: no-repeat;
  height: 50vh;
  color: #fff;
  display: flex;
  align-items: center; */
/* } */

.mbbs_section {
  position: relative;
  padding: 3rem 0;
  font-weight: 500;
  color: #555;
  background-color: #feeeed;
  background-size: contain;
  background-position: bottom right;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(43deg, #f0606038, #ffcd383b),
    url(../images/banner/mbbsBack.jpg);
  background-repeat: no-repeat;
}

.mbbs_section .section_wrapper {
  display: flex;
  gap: 5px;
  align-items: center;
}

img.country_flag {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.mbbs_list {
  display: flex;
  align-items: center;
  gap: 5px;
  list-style: none;
}

.mbbs_section span {
  text-transform: capitalize;
}

.mbbs_details_block {
  display: flex;
}

.mbbs_section_left {
  width: 90%;
  position: relative;
}

.mbbs_section_right {
  width: 30%;
  margin-left: 20px;
}

.mbbs_overview h2 {
  text-align: left;
}

.header_content_mbbs {
  text-align: left;
  padding: 10px 0px;
}

.table_responsive {
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
}

.dynamic_table_content {
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-collapse: collapse;
  width: 100%;
  background: white;
}

.table_responsive th:nth-child(1) {
  text-align: left !important;
}

.dynamic_table_content th {
  background-color: #fef4d0;
  padding: 0.75rem;
}

.dynamic_table_content tr {
  border-bottom: 1px solid #000;
}

.dynamic_table_content td {
  padding: 0.75rem;
  border: 1px solid #80808038;
}

/* .table_responsive td:nth-child(1) {
  background-color: #fef4d0;
} */

.mbbs_lists ul li {
  padding: 5px;
}

.mbbs_lists ul svg {
  color: rgb(255, 81, 0);
  margin-right: 5px;
}

.mbbs_eligibility_wrapper {
  display: flex;
  width: 100%;
}

.mbbs_eligi_left {
  width: 40%;
}

.mbbs_eligi_right {
  width: 80%;
  padding: 10px;
}

.mbbs_eligi_right ul li {
  padding: 10px 5px;
}

.mbbs_eligi_right ul svg {
  color: rgb(255, 81, 0);
  margin-right: 5px;
}

.eligibility_lists li {
  padding: 5px;
}

.eligibility_lists li svg {
  rotate: 45deg;
  margin-right: 5px;
  color: brown;
}

.mbbs_section_wrapper {
  background-color: #f4f4f4;
}

.social_media_icons {
  position: absolute;
  /* bottom: 0; */
  right: 5px;
  top: -50px;
  display: flex;
  gap: 0.5rem;
}

.social_media_icons a {
  box-shadow: 0 0 1px 1px;
  border-radius: 5px;
  width: 28px;
  height: 28px;
  text-align: center !important;
  margin: 0;
  padding: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  background: #fff !important;
}

.fb_share {
  color: #3b5998 !important;
}

.what_share {
  color: green;
}

a.linked_share {
  color: #007bb6;
}

a.insta_share {
  color: #fccc63;
}

.social_media_icons span {
  display: flex;
  gap: 5px;
  align-items: center;
}

.twitter_share {
  color: #00aced;
}

.desc {
  text-align: justify;
}

.blog-title {
  position: relative;
  padding-left: 30px;
  line-height: 1;
  margin-bottom: 26px;
  color: inherit !important;
  margin-bottom: 1rem;
}

.blog_content {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 10px;
}

.blog_content span {
  color: #c90404;
}

.blog_thumb img {
  /* width: 100%; */
  height: 100%;
  border-radius: 10px;
  min-height: 80px;
  max-height: 90px;
  border: 1px solid #ddd;
}

.blog_head {
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 500;
}

.blog_wrapper {
  margin-bottom: 30px;
}

.blog-title::after {
  content: "";
  height: 3px;
  width: 21px;
  background-color: #ee212b;
  position: absolute;
  left: 0;
  z-index: 1;
  top: 47%;
}

.quick_links_wrapper {
  display: flex;
  align-items: center;
  padding: 0px 30px;
}

.quick_link_left,
.quick_link_right {
  width: 30%;
}

.quick_links_wrapper ul {
  list-style-type: disc;
  color: blueviolet;
}

.quick_link_left li,
.quick_link_right li {
  padding: 10px 0px;
}

.quick_link_left li a,
.quick_link_right li a {
  padding: 10px 0px;
  color: blueviolet;
}

/* blogs */

.blog_cards_wrapper {
  display: flex;
  flex-wrap: wrap;
}

.blog_item {
  width: calc(100% / 3);
}

.blog_card {
  margin: 0px 10px;
  background: #fff;
  overflow: hidden;
  margin-bottom: 20px;
}

.blog_card:hover img {
  transform: scale(1.2);
}

.blog_card_thumb img {
  width: 100%;
  height: 150px;
  transform: scale(1);
  transition: 0.4s ease-in-out;
  /* object-fit: contain; */
  border-radius: 10px;
}

.blog_card_content {
  padding: 10px;
}

.blog_card_content h3 {
  font-size: 15px;
  color: #000;
}

.blog_card_content p {
  font-size: 13px;
  margin-bottom: 10px;
}

.blog_link {
  color: #c90404;
  font-size: 15px;
  padding: 5px;
  line-height: 25px;
  height: 30px;
  width: 120px;
  border: 2px solid #c90404;
  margin-top: 20px;
  border-radius: 10px;
}

/* faqs */
.faq_wrapper {
  display: flex;
  flex-direction: column;
}
.faq_wrapper .accordion {
  width: 800px;
}

.faq_wrapper .item {
  margin-bottom: 10px;
  position: relative;
}

.accordion .title {
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 21px 1.4rem;
  background-color: #fff;
}

.accordion strong {
  font-size: larger;
  font-weight: 800;
}

.accordion .content {
  color: #000;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
  margin-top: 0px;
  padding: 10px 1.4rem;
  /* border-top: 1px solid #8b7f75; */
  background-color: #fff;
  opacity: 0;
}

.content.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
  opacity: 1;
}

.faq_wrapper .item:hover .title {
  background-color: #c90404;
  color: #fff;
}

.title.show {
  background-color: #c90404;
  color: #fff;
}

.title svg {
  font-size: 25px;
}

.title label {
  font-size: 20px;
}

.mbbs_add_right {
  width: 40%;
}

.mbbs_add_left {
  width: 60%;
}
@media (max-width: 565px) {
  .mbbs_details_block {
    display: block;
  }

  .mbbs_section_left {
    width: 100%;
  }

  .mbbs_section_right {
    width: 100%;
  }

  .mbbs_eligibility_wrapper {
    display: block;
  }

  .mbbs_eligi_left {
    width: 100%;
  }

  .mbbs_eligi_right {
    width: 100%;
  }

  .faq_wrapper .accordion {
    width: 100% !important;
  }

  .blog_item {
    width: calc(100% / 1);
  }

  .mbbs_section_right {
    width: 100%;
    margin-left: 0px;
  }

  .blog_card {
    margin-bottom: 10px;
  }

  .quick_link_left,
  .quick_link_right {
    width: 50%;
  }

  .mbbs_add_right {
    width: 100%;
  }

  .mbbs_add_left {
    width: 100%;
  }
}

.mbbs_overview .lists {
  list-style-type: disc !important;
}

.mbbs_overview ul li,
ol li {
  padding: 5px;
}

.btn.btn-success {
  background-color: #198754;
  color: #fff;
  position: relative; /* Add this line */
  padding: 10px;
  min-width: 150px;
  line-height: 28px;
  text-align: center;
  border-radius: 10px;
  font-size: 15px;
  transition: width 0.3s ease;
  display: inline-block;
}

.btn.btn-success:hover {
  color: #198754;
  background-color: #fff;
  border-radius: 3px;
  border: 2px solid #198754;
}
.btn.btn-success:hover span {
  color: #198754 !important;
}

.two_buttons_footer{
  display: none;
}

@media (max-width: 767px) {
  .two_buttons_footer svg{
    width: 18px;  
  }
  .two_buttons_footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    background-color: #0d6efd;
    z-index: 9;
  }
  .enquiry_btn {
    width: 50%;
    border: 0;
    padding: 17px 10px;
    background-color: #f68c18;
    color: #ffff;
    font-size: 16px;
    font-weight: bold;
  }
  
  .two_buttons_footer a.call.btn.btn-primary {
    width: 50%;
    margin: 0;
    padding: 7px 8px;
    color: #ffff;
  }
  .two_buttons_footer {
      display: flex !important;
      align-items: center;
  }
}