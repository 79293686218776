.team_section {
  position: relative;
  /* padding: 3rem 0; */
  font-weight: 500;
  color: #555;
  background-color: #feeeed;
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid rgba(207, 179, 179, 0.1);
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../images/team/team.jpg);
  background-repeat: no-repeat;
  height: 50vh;
  color: #fff;
  display: flex;
  align-items: center;
}

.team_wrapper {
  background: #304030;
  color: #fff;
  z-index: -4;
}

.team_wrapper p {
  color: #fff;
}

.ceo_wrapper {
  display: flex;
  justify-content: space-between;
}

.ceo_thumb img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  /* border: 2px solid rgb(70, 18, 18); */
  padding: 2px;
  /* z-index: 999; */
  /* border: 2px solid orange; */
}

.ceo_thumb::before {
  content: "";
  display: block;
  border: 2px solid rgb(70, 18, 18);

  width: 300px;
  height: 300px;
  border-radius: 50%;
  /* padding: 10px; */
  position: absolute;
  rotate: 20deg;
  z-index: 1;
  /* margin-left: 20px; */
}

.ceo_first {
  width: 50%;
  margin-right: 10px;
}
/* .ceo_sec {
  width: 50%;
  margin-right: 10px;
}
*/
.ceo_third {
  width: 50%;
  margin-right: 10px;
  margin-top: 20px;
}

.management_team_items {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.team_item {
  width: calc(100% / 4);
  margin-bottom: 10px;
}

.team_card {
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  position: relative;
  padding: 10px;
  background: white;
  cursor: pointer;
  box-shadow: #3333331a 0 0 20px;
  margin-right: 20px;
}

.team_img {
  position: relative;
  overflow: hidden;
  border: 5px;
}

@media (max-width: 565px) {
  .ceo_wrapper {
    display: block;
  }

  .ceo_first {
    width: 100%;
    margin-right: 0px;
  }
  .ceo_sec {
    width: 100%;
    margin-right: 0px;
  }
  .ceo_third {
    width: 100%;
    margin-right: 0px;
  }

  .management_team_items {
    display: block;
    width: 100%;
  }

  .team_item {
    width: calc(100% / 1);
    margin-bottom: 10px;
  }

  .ceo_thumb::before {
    display: none;
  }

  .ceo_thumb img {
    width: 100%;
    height: 400px;
    border-radius: 50%;
    border: 2px solid rgb(70, 18, 18);
    padding: 2px;
    /* z-index: 999; */
    /* border: 2px solid orange; */
  }
}
