.career_sec {
    position: relative;
    /* padding: 3rem 0; */
    font-weight: 500;
    color: #555;
    background-color: #feeeed;
    background-size: cover;
    background-position: center;
    border-bottom: 1px solid rgba(207, 179, 179, 0.1);
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url(../images/banner/career_banner.webp);
    background-repeat: no-repeat;
    height: 50vh;
    color: #fff;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    padding: 50px;
}

.career_cate_sec {
    display: flex;
    gap: 20px;
    align-items: center;

}

/* .career_sec_cards */

.career_title {
    margin-bottom: 20px;
}

.career_sec_cards h3 {
    font-size: 20px !important;
    margin: 10px 0px
}