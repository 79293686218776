.services {
  position: relative;
  /* padding: 3rem 0; */
  font-weight: 500;
  color: #555;
  background-color: #feeeed;
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid rgba(207, 179, 179, 0.1);
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../images/services/services.jpg);
  background-repeat: no-repeat;
  height: 50vh;
  color: #fff;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.service_wrapper {
  background: rgb(231, 224, 224);
}

.service_wrapper_group {
  width: 100%;
  display: flex;
}

.service_wrapper_left {
  width: 30%;
  margin-right: 20px;
}

.service_wrapper_right {
  width: 90%;
}

.services_filter_card {
  padding: 25px;
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
  background: #fff;
  top: 150px;
  position: sticky;
  margin-left: 20px;
  padding: 10px;
}

.service_wrapper_left .service_filter_lists > li {
  padding: 10px;
  border-bottom: 1px solid green;
  cursor: pointer;
}

.service_pro {
  display: flex;
  align-items: center;
}

.service_pro_left {
  width: 80%;
}

.service_pro_right {
  width: 40%;
}

.services_content .title h3 {
  text-transform: capitalize;
}

.featured_content_lists li {
  padding: 10px;
}

.featured_content_lists li svg {
  color: rgb(68, 27, 95);
}

.services_filter_card .title {
  padding: 5px;
}

.services_filter_card .title h4 {
  border-bottom: 2px solid green;
}

.service_filter_lists li svg {
  margin-right: 10px;
}

@media (max-width: 567px) {
  .service_wrapper_group {
    width: 100%;
    display: block;
  }

  .service_wrapper_left {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .service_wrapper_right {
    width: 100%;
  }

  .service_pro {
    display: block;
  }

  .service_pro_left {
    width: 100%;
  }

  .service_pro_right {
    width: 100%;
  }

  .services_filter_card {
    margin-left: 0px;
  }
}
