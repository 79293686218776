.box-wrapper-blog {
  box-shadow: 0px 0px 5px 0px gray;
  border-radius: 10px;
  margin-bottom: 20px;
}

input.search-blog {
  width: 100%;
  padding: 15px 15px;
  border-radius: 25px;
  border: 1px solid gray;
}
.blog-image img {
  width: 100%;
  height: 250px;
  border-radius: 10px 0px 0px 10px;
  object-fit: cover;
}

.mbbs_quick_links img {
  width: 100%;
}
.blog-image {
  margin-right: 20px;
}

.blog-content a {
  color: #494949;
}

img.banner-feature-image {
  height: auto;
  width: 100%;
}

.blog-content {
  padding: 15px;
}

.category-blog {
  margin-top: 20px;
  border-radius: 10px;
  padding: 15px 15px;
  position: sticky;
  top: 200px;
}

.category-blog a {
  color: #000;
}

.list-of-blog-cate li {
  padding: 5px 0;
}


.blogtopuniversity h3{
  font-size: 20px;
}