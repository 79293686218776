.university_section_detail {
  background-color: rgb(197, 187, 187);
  padding: 4rem 0px;
}

.university_details_wrapper {
  display: flex;
  width: 100%;
}

.university_detail_left {
  margin: 0 10px;
}

.university_detail_right {
  width: 30%;
}

.university_btn_grp a {
  text-align: center;
  margin-bottom: 10px;
  width: 120px;
  color: #000;
}

.university_card {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0 10px #00000012;
}

.university_thmb {
  position: relative;
  overflow: hidden;
}

.university_thmb img {
  width: 100%;
  height: 300px;
}

.university_thmb:hover img {
  transform: scale(1.1);
}

.university_thmb:before {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 100%;
  box-shadow: inset 0 -100px 50px -30px #000000b3;
  /* z-index: 1; */
}

.university_space_content {
  padding: 1rem;
}

.star_content {
  right: 10px;
  position: absolute;
  bottom: 10px;
  /* z-index: 1; */
  font-family: bootstrap-icons;
  letter-spacing: 3px;
  line-height: 1.1;
}

.university_name {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 10px;
  left: 90px;
  padding: 0 30px;
  /* z-index: 1; */
  font-size: 2rem;
  color: #fff;
}

.university_content_top_left {
  margin-top: -80px;
  z-index: 1;
}

.university_top_left_img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.university_top_left_img img {
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 0px 2px 1px black;
  width: 100%;
  height: 100%;
}

.university_content_top {
  display: grid;
  grid-template-columns: 110px 1fr;
  gap: 15px;
}

.resp_uni_header {
  display: none;
}

.university_detail_top_right_lists {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 15px;
}

.uni_list {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.uni_list > li::after {
  content: "";
  height: 100%;
  top: 0;
  width: 1px;
  background: #ddd;
  position: absolute;
  right: 0;
}

.uni_list img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.university_bottom_content {
  display: flex;
  align-items: center;
  column-gap: 30px;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #ddd;
  width: 100%;
}

.uni_tab_lists {
  display: flex;
  column-gap: 15px;
  align-items: center;
  width: 100%;
}

.tab_list.active {
  border-bottom: 1px solid #c90404;
  color: #c90404;
}

.university_detail_content {
  box-shadow: #3333331a 0 0 40px;
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  width: 100%;
}

.uni_overview_lists {
  display: flex;
  gap: 30px;
}

.university_detail_lists li {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 10px;
}

.data--head {
  font-weight: 600;
}

.uni_overview_desc {
  margin-top: 20px;
}

.eligibility_lists_uni li {
  padding: 5px;
}

.eligibility_lists_uni li svg {
  color: orange;
  margin-right: 5px;
  font-size: 20px;
}

.university_form_wrapper {
  display: flex;
  flex-direction: row-reverse;
}

.uni_form {
  width: 50%;
}

.uni_form_thu {
  width: 50%;
}

.counsul_form_wrapp {
  background-color: #fff;
  padding: 5px;
}

.counsul_form_wrapp {
  top: 140px !important;
  position: sticky !important;
  padding: 10px;
  border-radius: 10px;
}

.counsul_form_wrapp .title {
  margin-left: 20px;
}

.tab_list svg {
  display: none !important;
}

.uni_tab_lists h5 {
  font-size: 18px;
}

/* Table design */
figure.table th {
  background-color: #f5821f;
  padding: 10px 20px;
  color: white;
  border: 1px solid black;
}

figure.table table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid;
}

figure.table table tr td {
  text-align: center;
  border: 1px solid;
  padding: 10px 10px;
}

.uni_overview_desc ul strong {
  color: #f5821f;
  font-weight: bolder;
}

.uni_overview_desc ul li {
  margin-bottom: 10px;
}

.university_gallery_content {
  padding: 15px 15px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
  flex-wrap: wrap;
  justify-content: space-around;
}

.university_gallery_content .gallery-box {
  width: calc(33% - 10px);
  margin-bottom: 10px;
  border-radius: 5px;
}

.gallery-box img {
  width: 100%;
  border-radius: 5px;
}

/* End of table and gallery css */

@media (max-width: 565px) {
  .university_details_wrapper {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .university_detail_left {
    width: 100%;
  }

  .university_detail_right {
    width: 100%;
  }

  .uni_tab_lists {
    display: block;
  }

  .uni_tab_lists li {
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: none;
  }

  .uni_overview_lists {
    display: block;
  }

  .counsul_form_wrapp {
    margin-top: 30px !important;
    z-index: 9;
    position: block !important;
    margin-left: 0px;
    padding: 10px;
  }

  .tab_list svg {
    display: block !important;
  }

  .tab_list.active {
    border-bottom: none;
  }

  .university_form_wrapper {
    display: block;
  }

  .uni_form {
    width: 100%;
  }

  .uni_form_thu {
    width: 100%;
  }

  .resp_uni_header {
    display: block;
  }

  .university_name {
    display: none;
  }

  .university_content_top {
    display: block;
  }

  .university_content_top_left {
    margin-top: -10px;
    z-index: 1;
  }

  .university_btn_grp a {
    width: 90px;
    font-size: 12px;
  }
}
