.footer_section {
  width: 100%;
  background: #15417f;
}

.footer_section p {
  color: #ffffff;
}

.footer_section_wrapper {
  width: 100%;
  display: flex;
}

.social_media_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.footer_content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer_content .dropdown {
  display: none;
}

.footer_items {
  width: 40%;
  color: #fff;
}

.footer_items .add_sec {
  width: 100%;
  margin: 5px 10px
}

.footer_menu li a {
  color: #fff;
  padding: 5px;
}

.footer_mid_section {
  background: #0c2667;
  padding: 1rem 0;
}

.footer_add_section {
  background: #0f121a;
  padding: 1rem 0;
}

.footer_end_section {
  padding: 10px 0px;
}

.social_media_ico {
  display: flex;
  align-items: center;
  gap: 15px;
}

.contact_info {
  display: flex;
  gap: 5px;
  align-items: center;
}

.footer_policies {
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.footer_policies .col {
  display: flex;
  align-items: center;
  gap: 2px;
}

/* .footer_policies p {
  font-size: 20px;
} */
.footer_section p:hover,
.footer_section li:hover,
.footer_section li a:hover,
.footer_section svg:hover {
  color: #f5821f;
}

.video-container {
  position: relative;
  top: 0;
  left: 0;
  height: 700px;
  width: 100%;
  overflow: hidden;
  z-index: -1;
  padding: 20px;
  margin-top: -200px;
}
@media (max-width: 768px) {
  .footer_content {
    justify-content: space-between;
  }

  .footer_items {
    width: 100%;
  }
}

@media (max-width: 550px) {
  .footer_section_wrapper {
    display: block;
    padding: 20px;
  }

  .footer_items {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .footer_menu {
    display: none;
  }

  .footer_menu.show {
    display: block;
  }

  .social_media_wrapper {
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
    justify-content: center;
  }

  .social_media_wrapper .footer_items {
    border: none;
    margin-bottom: 10px;
  }

  .footer_content .dropdown {
    display: block;
  }

  .footer_policies {
    flex-direction: column;
    align-items: center;
  }
.video-container {
  height: 600px;
  padding: 0px;
  /* Ensures the video is behind the content */
}
}


.ofc_location li {
  margin: 15px 10px;
}

.footer_add {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mt-1 {
  margin-top: 10px;
}



.footer_add_section h3 {
  color: #fff;
}