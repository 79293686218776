.about_section {
  position: relative;
  /* padding: 3rem 0; */
  font-weight: 500;
  color: #555;
  background-color: #feeeed;
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid rgba(207, 179, 179, 0.1);
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../images/about/about.jpg);
  background-repeat: no-repeat;
  height: 50vh;
  color: #fff;
  display: flex;
  align-items: center;
}

/* .about_wrapper {
  background-image: url(../images/about/about.jpg);
  background-position: center;
  width: 100%;
  height: 300px;
  background-size: cover;
} */

.about_desc {
  display: flex;
  align-items: center;
}

.about_desc_left {
  width: 50%;
}

.about_desc_right {
  width: 50%;
  padding: 20px;
}

.mission_wrapper {
  display: flex;
  justify-content: center;
}

.history_section {
  position: relative;
  /* padding: 3rem 0; */
  font-weight: 500;
  background-color: #feeeed;
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid rgba(207, 179, 179, 0.1);
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../images/about/history.jpg);
  background-repeat: no-repeat;
  color: #fff !important;
  display: flex;
  align-items: center;
}

.history_section p {
  color: #fff !important;
}

.img-vision img {
  width: 350px;
  height: 350px;
}

.img-vision {
  display: flex;
  justify-content: center;
}

.revese_column {
  flex-direction: row-reverse;
}
@media (max-width: 565px) {
  .about_desc {
    display: block;
  }

  .about_desc_left {
    width: 100%;
  }

  .about_desc_right {
    width: 100%;
    padding: 20px;
  }
.img-vision img {
  width: 250px;
  height: 250px;
}
}
