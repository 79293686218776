.desktop_banner {
  position: relative;
  z-index: -1;
}

.mobile_banner {
  display: none;
  /* Hide mobile images by default */
}

.desktop_banner {
  display: flex;
  /* Show desktop images by default */
}

.enquiry_form {
  right: 5%;
  top: 65%;
  transform: translateY(-50%);
  width: 100%;
  max-width: 350px;
  bottom: inherit;
  position: absolute;
  background: rgb(255, 255, 255);
  padding: 30px;
  border-radius: 5px;
  box-shadow: #3333331a 0 0 40px;
}

.enquiry_form h4 {
  background: #f5821f;
  color: #ffff;
  margin-top: -30px;
  padding: 10px 0px;
  font-size: 18px;
  box-shadow: 0px 0px 1px 1px gray;
  border-radius: 0px 0px 40px 40px;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="file"],
select,
select option,
textarea {
  padding: 13px 5px;
  border-radius: 5px;
  border: 1px solid #87878785;
  width: 100%;
  background: white;
  outline: none;
}

.course_card:hover {
  transform: translateY(-10px);
  background-color: #f5821f;
  border-color: #f5821f;
  color: #ffffff;
  text-decoration: none;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 35px;
  border-radius: 10px;
  background-color: #f5821f;
  height: 15px;
}

span.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  background-color: #fdfdfd;
  opacity: inherit;
  box-shadow: 0px 0px 0px 2px #f3be2a;
}

.form-content {
  margin: 10px 0px;
}

section.main_wrapper {
  position: relative;
}

.enquiry_form .apply_btn {
  margin-left: 20px;
}

/* fields */

.field_section {
  background: rgb(243, 236, 236);
}

.fields_items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.field_item {
  width: calc(33.33% - 20px);
  margin-bottom: 20px;
  box-shadow: 0px 0px 14px 3px #cbcbcb;
  border-radius: 10px;
}

.image_card {
  position: relative;
  transition: all 0.9s;
  overflow: hidden;
  border-radius: 5px;
}

.image_card img {
  width: 100%;
  height: auto;
}

.image_card:hover img {
  transform: scale(1.2);
  transition-duration: 0.8s;
  cursor: pointer;
}

.image_card figcaption {
  width: 100%;
  font-size: 1.2rem;
  top: 50%;
  position: absolute;
  left: 50%;
  padding: 0px 15px;
  transform: translate(-50%, -50%);
}

figcaption h3 {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 20px;
  color: #ffc107;
  max-width: 100%;
}

figcaption span {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1rem;
  color: #fff;
}

/*  Notification ims */

/* width */
.notification-box::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.notification-box::-webkit-scrollbar-track {
  background: #00000047;
}

/* Handle */
.notification-box::-webkit-scrollbar-thumb {
  background: #f5821f;
  border-radius: 5px;
}

/* Handle on hover */
.notification-box::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.notification-tab {
  background-color: #f9cea9;
  height: 100%;
  padding: 20px 20px;
  border-radius: 10px;
}

.notification-tab .nav.nav-tabs li a {
  display: flex;
  color: #f5821f;
}

.slider-image-notification {
  height: auto;
  object-fit: cover;
  width: 100%;
}

.notification-tab .nav.nav-tabs li {
  width: calc(33.33% - 7px);
  text-align: center;
  display: inline-block;
  padding: 10px 10px;
  cursor: pointer;
  border-bottom: 1px solid #f5821f;
}

.notification-tab .nav.nav-tabs li.active {
  background-color: #f5b984;
  border-bottom: 1px solid #f5b984;
  border-left: 1px solid #f5821f;
  border-top: 1px solid #f5821f;
  border-right: 1px solid #f5821f;
  border-radius: 5px 5px 0px 0px;
}

ul.nav.nav-tabs li:hover {
  background-color: #f5b984;
}

.notification-tab .nav.nav-tabs li a img {
  width: auto;
  height: fit-content;
}

.notification-tab .nav.nav-tabs li a img {
  width: auto;
  height: fit-content;
}

ul.notificatoinlistunder a {
  color: #f5821f;
  font-size: 14px;
}

ul.notificatoinlistunder .notification {
  padding: 10px 10px;
  border-bottom: 1px solid #f5821f;
  display: inline-flex;
  align-items: flex-start;
  width: 100%;
}

.notification-box {
  height: 270px;
  overflow-y: auto;
}

.notification-box .inactive {
  display: none;
}

/*  End of Notification */
.fs-content {
  padding: 1rem 1.5rem;
  border-radius: 0 0 5px 5px;
  background: white;
  color: #333;
}

/* courses */

.courses_wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.course_item {
  width: calc(100% / 3);
  margin-bottom: 10px;
}

.course_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  transition: all 0.2s ease-out;
  padding: 1.6rem;
  border: 2px solid #eef0f6;
  background-color: #fff;
  color: #333;
  margin-right: 20px;
  cursor: pointer;
}

.course_card:hover svg {
  color: red;
}

/* search card */

.university_search {
  background: url("../images/banner/students-bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  z-index: 1;
}

.university_search::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: -1;
}

.search_form {
  background: rgb(255, 255, 255);
  padding: 30px;
  border-radius: var(--border-radius-sm);
  box-shadow: #3333331a 0 0 8px;
  border-radius: 10px;
}

.form_wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.search_form_item {
  width: 32.5%;
}

.service_item img {
  width: 60px;
  margin: auto;
}

.search_form_item .selectedCourse {
  width: 500px;
}

.search_btn_grp {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.search_btn_grp .apply_btn {
  margin-left: 0px;
  display: flex;
  align-items: center;
}

.apply_btn p {
  color: #fff;
}

.search_btn_grp .apply_btn>svg:first-child {
  margin-right: 10px;
  transform: scale(1.1);
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  padding-right: 10px;
  height: 100%;
  line-height: 28px;
  width: 40px;
}

.header_content span {
  color: #f5821f;
}

.icon_sec {
  background: #f5821f;
  padding: 10px;
  min-width: 50px;
  line-height: 28px;
  text-align: center;
  border-radius: 10px;
  font-size: 15px;
  border-right: none;
  font-weight: 600;
  letter-spacing: 0.75px;
  color: #fff !important;
  cursor: pointer;
  margin-right: 0 !important;
  border-right: 1px solid #fff;
}

.review-card figure>figcaption {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: max-content;
  left: 0;
  bottom: 0;
  padding: 20px;
}

.review-card figure>figcaption>* {
  color: #000;
  width: max-content;
  padding: 0 10px;
}

.video-play-btn {
  font-size: 30px;
  height: 60px;
  width: 60px;
}

.video-play-btn {
  font-size: 35px;
  height: 60px;
  width: 60px;
  /* color: var(--color-primary); */
  background: rgba(255, 255, 255, 0.6);
  border: 0;
  border-radius: 100%;
  display: inline-block;
  margin: 0 auto;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1);
  position: absolute;
  left: 40%;
  top: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-media span {
  position: absolute;
  top: 5px;
  right: 20px;
  font-size: 50px;
  font-weight: bolder;
  z-index: 100;
  cursor: pointer;
  user-select: none;
  color: #fff;
}

.container .popup-media {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
}

.container .popup-media iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 50%;
  height: 50%;
  object-fit: contain;
  border: 3px solid #fff;
}

.review-card img {
  width: 266px;
}

.success_card {
  background: rgb(236, 229, 229);
}

.success_card_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.success_left {
  width: 70%;
}

.success_right {
  width: 28%;
}

.counter_cards {
  display: flex;
  flex-wrap: wrap;
}

.counter_item {
  width: calc(100% / 2);
}

.counter_item span {
  color: rgb(245 130 31);
}

.reliable_service_wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.service_item {
  width: calc(100% / 5);
}

.service_item:hover p {
  color: rgb(236, 62, 62);
}

.coverage {
  background: #fff;
}

.coverage_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;
}

/* .media_item {
  width: calc(100% / 4);
} */

.media_item img {
  width: 200px !important;
  display: flex !important;
  justify-content: center !important;
  border-radius: 5px;
  background: white;
}

.contact_sec {
  margin-bottom: -1rem;
  position: relative;
  z-index: 2;
}

.yellow_box {
  top: 15px;
}

.yellow_box {
  background: #f68c1f;
  position: absolute;
  z-index: 2;
  top: -4em;
  border-radius: 10px !important;
  width: 100%;
  padding: 25px;
  color: #fff !important;
  line-height: normal;
  border-radius: var(--border-radius-sm);
  text-decoration: none !important;
}

.yellow_box:before {
  content: "";
  position: absolute;
  right: calc(46% - 80px);
  background: #000;
  transform: skew(-30deg);
  height: 100%;
  width: 100px;
  top: 0px;
}

.yellow_box:after {
  content: "";
  position: absolute;
  width: 42%;
  background-color: #000;
  top: 0px;
  height: 100%;
  right: 0px;
  border-radius: 5px;
}

.yellow_box_wrapper {
  display: flex;
  align-items: center;
}

.yellow_left {
  width: 80%;
}

.yellow_right {
  width: 40%;
  /* background-color: #fecc00; */
  background-color: #000 !important;
}

.get_call {
  display: flex;
  align-items: center;
  gap: 20px;
}

.form_wrapper {
  position: relative;
  z-index: 2;
}

.call_no {
  font-size: 30px;
  font-weight: bold;
}

.contact_input {
  height: 50px;
  width: 70% !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border: none;
  z-index: 2;
  position: absolute;
  padding: 10px;
}

.contact_btn {
  position: absolute;
  width: 30%;
  right: 0px;
  top: -25px;
  border-radius: 0 3px 3px 0;
  padding: 10px;
  height: 50px;
  background: #f68c1f;
  color: #fff;
  border: none;
  z-index: 2;
  line-height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact_input::placeholder {
  text-align: left;
  font-weight: bold;
  color: #000;
  font-size: 20px;
  opacity: 0.3;
}

.counceller {
  margin-top: 50px;
}

.counceller_card {
  background: #ffdeda;
  border-radius: var(--border-radius-md);
  top: 0;
  padding: 3rem;
  transform: translateY(-120px);
  position: absolute;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 7px 1px #b9b9b9;
}

.councel_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.councel_add_item {
  display: flex;
  align-items: center;
  background: #15417f;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 8px 8px;
  color: #fff;
  cursor: pointer;
}

.councel_add_item p {
  font-size: 18px;
  color: #ffffff;
  margin-left: 20px;
  font-weight: 500;
}

.webinar {
  background-image: url("../images/banner/webinar.jpg");
  width: 100%;
  background-position: center;
  background-size: cover;
}

.webinar_wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.webinar_item {
  width: 33.33%;
}

.webinar_card {
  padding: 20px;
  background-color: #fff;
  box-shadow: -2px 0 11px #0000001a;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 10px;
}

.event_time {
  display: grid;
  grid-template-columns: 85px 1fr;
  align-items: center;
  border-radius: 50px;
  border: 1px solid #ddd;
  background: #ffcfa6;
  margin-bottom: 1rem;
  margin-top: 20px;
}

.event_day {
  --val: 66px;
  width: var(--val);
  height: var(--val);
  border-radius: 50%;
  background: #f5821f;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  border: 7px solid #ffffff73;
  flex-direction: column;
}

.event_day h4 {
  font-size: 18px;
}

.event_day h5 {
  font-size: 15px;
}

.solid_border_btn {
  background-color: transparent;
  color: #f5821f;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  border: 2px solid #f5821f;
  display: flex;
  height: 2.8rem;
  line-height: 2.6rem;
  font-size: 1rem;
  padding: 0px 1.5rem;
  width: 50%;
  margin-top: 10px;
}

/* clients Story */

.story_container_section .video-col-1 {
  width: 18%;
}

.story_container_section .video-col-2 {
  width: 21.33%;
}

.story_container_section .video-wrap {
  padding: 7px;
}

.story_container_section .video {
  position: relative;
}

.video-wrap .video .video-thumb.ratio1 {
  padding-bottom: 110%;
}

.video-wrap .video .video-thumb.ratio2 {
  padding-bottom: 80%;
}

.video-wrap .video .video-thumb {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  line-height: 0;
}

.video-wrap .video .video-thumb:not(.default) img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 0.3s;
}

.video-wrap .video .video-thumb .play-video {
  width: 48px;
  height: 48px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
  transform: scale(0.9);
  transition: 0.3s;
  cursor: pointer;
}

.video-wrap .video .video-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  text-align: center;
  color: #fff;
  z-index: 5;
}

.video-wrap .video .video-caption p {
  font-size: 11px;
}

.video-wrap .video .video-caption p {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  color: #fff;
}

.story_container_section {
  display: flex;
  align-items: center;
  margin: 0 -7px;
}

.resp_story_container {
  display: none;
}

@media (max-width: 565px) {
  .yellow_left {
    width: 100%;
    margin-bottom: 5px;
  }

  .enquiry_form {
    position: relative;
    margin-top: 230px;
    padding: 20px;
    right: 0;
    width: 300px;
    margin-left: 0px;
  }

  .notification-tab .nav.nav-tabs li a {
    font-size: 12px;
  }

  input[type="text"],
  select,
  option {
    padding: 10px;
    border-radius: 10px;
    background: #f1f1f7;
    border: 1px solid #1118ff24;
    width: 100%;
    outline: none;
  }

  .field_section {
    padding-top: 0px !important;
  }

  .field_item {
    width: 100%;
    /* Display one card per row on smaller screens */
  }

  .courses_wrapper {
    display: block;
  }

  .course_item {
    width: calc(100% / 1);
  }

  .form_wrapper {
    display: block;
  }

  .search_form_item {
    width: 100%;
    margin-bottom: 20px;
  }

  .container .popup-media iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 90%;
    height: 50%;
    object-fit: contain;
    border: 3px solid #fff;
  }

  .mobile_banner {
    display: block;
    /* Show mobile images on screens less than or equal to 768px */
  }

  .desktop_banner {
    display: none;
    /* Hide desktop images on screens less than or equal to 768px */
  }

  .review-card img {
    width: 100%;
  }

  .success_card_wrapper {
    display: block;
  }

  .success_left {
    width: 100%;
  }

  .success_right {
    width: 100%;
  }

  .reliable_service_wrapper {
    display: flex;
  }

  .service_item {
    width: calc(100% / 2);
    margin-bottom: 20px;
  }

  .yellow_box {
    position: absolute;
    width: 96%;
    z-index: 2;
    top: -8em;
    padding-top: 10px;
    padding-bottom: 40px;
    border-radius: 10px !important;
    color: #fff !important;
    line-height: normal;
    border-radius: var(--border-radius-sm);
    text-decoration: none !important;
  }

  .yellow_box:after,
  .yellow_box:before {
    background: none;
  }

  .yellow_box_wrapper {
    display: block;
  }

  .yellow_left {
    width: 100%;
  }

  .yellow_right {
    width: 90%;
    padding-bottom: 20px;
  }

  .call_no {
    font-size: 20px;
    font-weight: bold;
  }

  .contact_btn {
    top: 0px;
    background-color: #000;
    color: #fff;
  }

  .media_item img {
    width: 150px !important;
  }

  .councel_wrapper {
    display: block;
  }

  .councel_tit {
    margin-bottom: 20px;
  }

  .councel_tit h3 {
    line-height: 1.8rem !important;
  }

  .councel_add {
    margin-bottom: 20px;
  }

  .councel_btn {
    margin-bottom: 20px;
  }

  .webinar_wrapper {
    width: 100%;
    display: block;
  }

  .webinar_item {
    width: 100%;
    margin-bottom: 10px;
  }

  /*  clients story */
  .story_container_section {
    display: none;
  }

  .story_container_section .video-col-1,
  .story_container_section .video-col-2 {
    width: 100%;
  }

  .video-wrap .video .video-thumb.ratio1 {
    padding-bottom: 80%;
  }

  .resp_story_container {
    display: block;
  }

  .video-wrap .video .video-thumb {
    margin-right: 5px;
  }

  .courses_sec {
    position: relative;
    margin-top: 200px;
  }

  .get_call svg {
    display: none;
  }

  h1 {
    font-size: 28px;
    line-height: 1.5;
  }

  h2 {
    font-size: 25;
    font-size: 21px;
  }

  h4 {
    font-size: 20px !important;
    line-height: 1.5em;
  }
}
.study_abro_con {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.study_abro_con .slide {
  width: 15%;
}

.sli_abro_img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: inline-block;
  box-shadow: 0 0 4px #0003;
}

.slide figure {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.slide {
  margin: 20px 0px;
}




.slide figure img:hover {
  transform: rotate(360deg);
}


@media (max-width:767px) {
  .study_abro_con .slide {
    width: 50%;
  }
}