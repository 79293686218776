.testimonial_wrapper {
  position: relative;
  /* padding: 3rem 0; */
  font-weight: 500;
  color: #555;
  background-color: #feeeed;
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid rgba(207, 179, 179, 0.1);
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../images/testimonial/testimonial.jpg);
  background-repeat: no-repeat;
  height: 50vh;
  color: #fff;
  display: flex;
  align-items: center;
}

.testimonial_cards {
  display: flex;
  width: 100%;
}

.testimonial_item {
  width: calc(100% / 3);
}

.testimonial_card {
  box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  padding: 30px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
}

.testimonial_intro p {
  color: rgb(133, 20, 20);
}

.testimonial_quote {
  position: absolute;
  top: 10px;
  right: 10px;
}

.testimonial_quote svg {
  color: red;
  font-size: 25px;
}

.testimonial_desc {
  margin-bottom: 5px;
}

.testimonial_rating svg {
  color: red;
}

@media (max-width: 567px) {
  .testimonial_cards {
    display: block;
    width: 100%;
  }

  .testimonial_item {
    width: calc(100% / 1);
  }
}
