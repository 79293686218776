.study_abroad_section {
  background-color: #f5efef;
  padding: 70px 0px;
}

.filter_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clear_all {
  cursor: pointer;
  color: #000;
}

.study_wrapper {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
}

.study_lft {
  width: 30%;
}

.study_rgt {
  width: 90%;
}

.univesity_title h3 {
  font-size: 20px;
}

.univesity_title a {
  color: #fff;
}

.university_card {
  margin-bottom: 20px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  position: relative;
}

.card_wrapper {
  display: flex;
}

.university_image {
  width: 30%;
  padding: 10px;
  position: relative;
}

.university_image img {
  border-radius: 10px;
}

.university_detail {
  width: 60%;
}

.university_sub_detail {
  width: 20%;
  background-color: #f0c2a326;
  border-radius: 0px 5px 5px 0px;
  display: flex;
  align-items: center;
}

.university_detail {
  padding: 10px 20px;
}

.uni_subtitle {
  display: flex;
  align-items: center;
  gap: 10px;
}
.univesity_title {
  padding: 5px;
  border-radius: 8px;
  border-bottom: 3px solid #cdcdcd;
  color: #fff !important;
}
.univesity_title h3 {
  /* padding: 5px; */
  border-radius: 8px;
  /* border-bottom: 3px solid #cdcdcd; */
  color: #fff !important;
}

.spe_wrap {
  display: flex !important;
  align-items: center;
  gap: 5px;
  padding: 3px;
  font-size: 14px;
  color: #000;
}

.spe_title::after {
  position: absolute;
  content: "";
  left: -8px;
  top: 0;
}

.spec_ans:before {
  position: absolute;
  content: "";
  left: -8px;
  top: 0;
}

.university_specification {
  display: flex;
  padding: 10px 0px;
  border-bottom: 1px solid #cdcdcd;
}

.univer_spec_left {
  width: 50%;
}
.univer_spec_right {
  width: 50%;
}

.filter_section {
  box-shadow: rgb(201 201 201 / 35%) 0px 5px 15px;
  border-radius: 5px;
  background: white;
  padding: 10px;
  margin-right: 20px;
}

.filter_tit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid orange;
}

.checkbox.selected {
  background-color: var(--color-primary);
}

li.select_item {
  display: flex;
  gap: 20px;
  padding: 10px;
  margin: 4px;
  border-radius: 5px;
  border: 1px solid #dadada;
  box-shadow: 0 2px 8px 0px rgb(235 235 235 / 59%);
}

.filter_tit {
  color: rgb(247, 26, 26);
  border: 1px solid #cfcfcf;
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
  background: whitesmoke;
  border-radius: 5px;
  margin: 5px 0px;
}

.filter_tit p {
  font-size: 15px;
  font-weight: bold;
  color: rgb(247, 26, 26);
}

.filter_btn {
  display: none;
}

.search_container {
  display: block;
  padding: 10px;
}

.search_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resp_menu {
  display: none;
}

.download_btn {
  border: 2px solid green;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: all ease 0.3s;
}

.download_btn:hover {
  background: green;
  color: #fff;
}

.apply_now_btn {
  border: 2px solid orange;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
}

.apply_now_btn:hover {
  background-color: orange;
  color: #fff;
}

.view_btn {
  border: 2px solid #0d6efd;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
}

.view_btn:hover {
  background-color: #0d6efd;
  color: #fff;
}

.modal_wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(189, 189, 189, 0.9);
  z-index: 99;
}

.modal_container {
  position: fixed;
  max-width: 40rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 2rem 3rem;
  border-radius: 0.5rem;
}

.my_modal {
  display: flex;
  align-items: center;
}

.modal_thumb {
  width: 50%;
}

.modal_desc {
  width: 50%;
  /* margin-left: 10px; */
}

@media (max-width: 565px) {
  .resp_menu {
    display: block;
  }

  .study_wrapper {
    display: block;
  }

  .resp_modal {
    width: 100%;
    position: fixed;
    top: 0px;
    right: 0;
    z-index: 999;
    transition: all 0.3s ease 0s;
    overflow-y: auto;
    height: 100vh;
    background-color: #fff;
  }

  .study_lft {
    display: none;
  }

  .study_rgt {
    width: 100%;
  }

  .search_container {
    display: block;
  }

  .card_wrapper {
    display: block;
  }

  .university_image {
    width: 100%;
  }

  .university_detail {
    width: 100%;
  }

  .filter_btn {
    width: max-content;
    padding: 5px 12px;
    box-shadow: #3333331a 0 0 10px;
    border-radius: 40px;
    background: #198754;
    color: #fff;
  }

  .my_modal {
    display: block;
  }

  .modal_thumb {
    width: 100%;
  }

  .modal_desc {
    width: 100%;
  }

  .modal_container {
    max-width: 100%;
    width: 95%;
    padding: 20px;
  }

  .university_sub_detail {
    width: 100%;
  }
}

/* .university_btn_grp {
  display: flex;
  gap: 20px;
  align-items: center;
} */

.university_sub_detail .university_btn_grp {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}
.university_sub_detail .university_btn_grp div {
  margin: 10px 0px;
  text-align: center;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 1s infinite;
  color: red;
  text-align: center;
}

.blink span {
  color: blue;
}

.uni_overlay {
  position: absolute;
  top: 15px;
  left: 20px;
  z-index: 1;
  background: #f5821f;
  border-radius: 5px;
  border: 0;
  outline: 0;
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  height: 20px;
  display: flex;
  align-items: center;
  padding: 5px;
}

.seats_avalable {
  position: absolute;
  bottom: 15px;
  right: 20px;
  z-index: 1;
  border-radius: 5px;
  border: 0;
  outline: 0;
  color: #ffffff;
  font-size: 15px;
  line-height: 12px;
  font-weight: 800;
  height: 20px;
  display: flex;
  align-items: center;
  padding: 5px;
}

.uni_course,
.uni_degree,
.uni_country {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.university_btn_grp {
  display: flex;
  gap: 10px;
}

@media (max-width: 565px) {
  .spe_title {
    font-size: 12px;
    font-weight: bold;
  }
  .spec_ans {
    font-size: 12px;
  }

  .university_btn_grp a {
    text-align: center;
    margin-bottom: 10px;
    width: 90px;
    color: #000;
    font-size: 12px;
  }

  .univesity_title {
    /* position: absolute; */
    top: 180px;
    color: #fff;
    border-bottom: none;
  }

  .univesity_title h3 {
    font-size: 20px;
  }

  .filter_btn {
    display: block;
  }

  .resp_none {
    display: none;
  }
}

.filter_btn_group {
  display: flex;
  gap: 5px;
  padding: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

input[type="text"],
select,
option {
  padding: 10px;
  border-radius: 10px;
  background: #f1f1f7;
  border: 1px solid #1118ff24;
  width: 100%;
  outline: none;
}

.filter_btn {
  padding: 8px 15px;
  border: 2px solid #6b0b0b;
  border-radius: 30px;
}
