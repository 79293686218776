section.top_menu {
  position: relative;
}

.top_menu_lists {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
  color: #000;
}

.top_menu_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top_menu_lists li a {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.main_menu {
  position: relative;
}

.main_menu_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_menu_lists {
  display: flex;
  gap: 15px;
  color: #000;
  /* margin-left: 200px; */
}

.main_menu_lists li a {
  color: #000;
}

.main_menu_lists li a:hover {
  color: #f5821f;
}

.main_menu_lists li {
  font-weight: bold;
}

.submenu__list {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 3px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  top: 50px;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  text-align: left;
  font-size: 1rem;
  visibility: hidden;
  z-index: 9999;
}

.submenu__list li a {
  color: black;

  font-weight: 500;
}

.submenu__list li {
  padding: 10px 10px;
  border-bottom: 1px solid #eee;
}

.submenu:hover>.submenu__list {
  z-index: 1;
  opacity: 1;
  visibility: visible;
}

.megamenu {
  width: 100%;
  position: fixed;
  height: 100vh;
  overflow-y: scroll;
  right: 0;
  top: 0;
  z-index: 9999;
  background: #f8f8f8;
  padding-top: 10px;
}

.close-menu {
  position: absolute;
  right: 10;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.megamenu_wrapper {
  margin-top: 30px;
}

.megamenu_wrapper.roboto-regular {
  display: flex;
}

.dropdown_wrapper {
  width: 33.33%;
}

.dropdown_primary {
  padding: 20px 0;
}

.dropdown_primary h4 {
  color: #f5821f;
}

.megamenu_dropdown_list li a {
  color: black;
}

.megamenu_dropdown_list li {
  padding: 10px 0;
}

.dropdown_primary {
  border-bottom: 1px solid #f5821f;
  padding: 5px;
}

.megamenu_dropdown_list li {
  display: flex;
  align-items: center;
}

.megamenu_dropdown_list li img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 5px;
  border: 2px solid #c4c3cd;
}

.megamenu_dropdown_list li:hover img {
  box-shadow: 0 0 10px 1px #00000026;
}

@media (max-width: 768px) {
  .top_menu {
    display: none;
  }

  .main_menu {
    display: none;
  }
}

.live-concelling a {
  align-items: center;
  border: 2px solid #c90404;
  border-radius: 5px;
  padding: 5px 10px;
  border-right: 2px solid #c90404 !important;
  position: relative;
}

.live-concelling a::before {
  transform: scale(1);
  animation: pulse 1s ease-out infinite;
}

.live-concelling a::after {
  animation: pulse 1s ease-out infinite;
}

.megamenu_dropdown_lists li {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

@keyframes pulse {
  100% {
    transform: scale(1.5);
  }
}

.admission_year_btn {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 9;
  transform: translateY(-50%);
}

.admission_year_btn .admission_year_content {
  writing-mode: vertical-lr;
  font-size: 1.2rem;
  background: #0c2667;
  color: #fff !important;
  padding: 20px 10px;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
}

.subdropdown li {
  padding: 5px;
  box-shadow: 0 0 10px 1px #00000026;
}

.logo_sec_menu {
  padding: 10px;
}

.submenu svg {
  margin-left: 2px;
}

.main_menu li {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navbar .modal_container {
  position: fixed;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 2rem 3rem;
  border-radius: 0.5rem;
  width: 50%;
}

.navbar .modal_desc {
  width: 100%;
}

.navbar .form-content {
  margin: 20px 0px;
}

.navigation_wrapper.sticky-form {
  position: fixed;
  top: 0;
  /* Change to 'bottom: 0' if you want the form to stick at the bottom */
  width: 100%;
  background-color: white;
  /* Adjust as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Optional: Add shadow for better visibility */
  z-index: 9;
  /* Adjust to make sure it's above other content */
}