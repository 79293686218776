.country_sec {
  display: flex;
  flex-wrap: wrap;
}

.countries_wrapper {
  background-color: #f4f4f4;
}

.country_item {
  width: 50%;
}

.country_card {
  background-color: #fff;
  padding: 15px;
  transition: all 0.9s;
  border-radius: 10px;
  margin: 20px;
  margin-right: 0px;
}

.country_card img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 0 10px 1px #00000026;
  border: 5px solid #fff;
}

.country_card .section_wrapper {
  display: flex;
  align-items: center;
  gap: 7px;
}

.coun_subtitle {
}

.count_btn_grop {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}

.count_btn_grop a {
  color: black;
  font-weight: 600;
}

.count_btn_grop a:hover {
  color: red;
}

.country_card:hover {
  border-bottom: 5px solid red;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.country_desc {
  margin: 8px 0px;
}

@media (max-width: 565px) {
  .country_item {
    width: 100%;
  }
}
