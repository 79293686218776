/* @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap");

body {
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  color: #0a0a0acc;
  font-size: 16px;
  overflow-x: hidden;
  /* Ensure no horizontal overflow */
}

:root {
  --color-primary: #ffc107;
}

.container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  max-width: 1200px;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

a:hover {
  color: #f5821f;
}

h3 {
  line-height: 1.3;
}

p {
  line-height: 1.5em;
  color: #333;
}

img {
  display: block;
}

/* Width */
.cm-wd-20 {
  width: 20%;
}

.cm-wd-25 {
  width: 25%;
}

.cm-wd-30 {
  width: 30%;
}

.cm-wd-33 {
  width: 33.33%;
}

.cm-wd-38 {
  width: 38%;
}

.cm-wd-40 {
  width: 40%;
}

.cm-wd-50 {
  width: 50%;
}

.cm-wd-60 {
  width: 60%;
}

.cm-wd-70 {
  width: 70%;
}

.cm-wd-75 {
  width: 75%;
}

.cm-wd-80 {
  width: 80%;
}

.cm-wd-100 {
  width: 100%;
}

/* Flex display */
.cm-flex {
  display: flex;
  flex-wrap: wrap;
}

.cm-flex-type-0 {
  display: flex;
  justify-content: space-between;
}

.cm-flex-type-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cm-flex-type-2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cm-flex-wrap {
  flex-wrap: wrap;
}

.cm-flex-type-3 {
  display: flex;
  justify-content: flex-start;
  margin: 0 -10px;
}

/* Margin */
.cm-mb-20 {
  margin-bottom: 20px;
}

.cm-mr-20 {
  margin-right: 20px;
}

/*  Box shadow */
.box-shadow-1 {
  box-shadow: 0px 0px 4px 4px #d5d5d5;
}

/* Border Radius */
.rounded-10 {
  border-radius: 10px;
}

/* Bedge */
.bedge-primary {
  border: 1px solid #f5821f;
  background-color: rgb(218, 218, 218);
  padding: 5px 10px;
  margin-top: 10px;
  border-radius: 10px;
  display: inline-block;
}

/* Rating */
.star-rating {
  font-size: 25px;
  padding: 2px 0px;
  margin: 0px 5px;
}

.apply_btn {
  background: #f5821f;
  padding: 8px 10px;
  text-align: center;
  border-radius: 5px;
  border: none;
  font-size: 15px;
  border-right: none;
  letter-spacing: 0.75px;
  color: #fff !important;
  cursor: pointer;
  display: inline-block;
}

.filterbtn {
  border: 1px solid #f5821f;
  margin-right: 10px;
  padding: 5px 8px;
  background-color: #f5f5f5;
  border-radius: 10px;
  font-size: 10px;
  margin: 5px 5px;
  display: inline-block;
}

.apply_btn:hover {
  color: rgb(255, 255, 255) !important;
  background-color: #15417f;
  transition: ease-in-out;
  transition-duration: 0.3s;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.header_content {
  padding: 30px;
  text-align: center;
}

/* Backgorund */
.bg-white {
  background-color: #fff;
}

.bg-secondary {
  background-color: #fecc00;
}

.bg-primary {
  background-color: #fecc00;
}

.bg-star {
  fill: #fecc00;
  font-size: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 34px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 25px;
  line-height: 1.5em;
}

h5 {
  font-size: 22px;
  line-height: 1.5em;
}

.font-18 {
  font-size: 18px;
}

.font-22 {
  font-size: 22px;
}

@media only screen and (max-width: 600px) {
  .cm-flex-wrap {
    flex-wrap: wrap;
  }

  .cm-mob-full {
    width: 100%;
  }
}

.explore_btn {
  /* background: #f5821f; */
  border: 2px solid #f5821f;
  padding: 10px;
  min-width: 150px;
  line-height: 28px;
  text-align: center;
  border-radius: 10px;
  font-size: 15px;
  transition: all ease-in-out 0.5s;
  color: #000;
  cursor: pointer;
}

.explore_btn:hover {
  background: #f5821f;
  border: none;
  color: #fff !important;
}

/* Loader Css */
.bodyLoader {
  position: fixed;
  background-color: #000000b3;
  width: 100%;
  height: 100vh;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  --background: linear-gradient(135deg, #23c4f8, #275efe);
  --shadow: rgba(39, 94, 254, 0.28);
  --text: #6c7486;
  --page: rgba(255, 255, 255, 0.36);
  --page-fold: rgba(255, 255, 255, 0.52);
  --duration: 3s;
  width: 200px;
  height: 140px;
  position: relative;
}

.loader:before,
.loader:after {
  --r: -6deg;
  content: "";
  position: absolute;
  bottom: 8px;
  width: 120px;
  top: 80%;
  box-shadow: 0 16px 12px var(--shadow);
  transform: rotate(var(--r));
}

.loader:before {
  left: 4px;
}

.loader:after {
  --r: 6deg;
  right: 4px;
}

.loader div {
  width: 100%;
  height: 100%;
  border-radius: 13px;
  position: relative;
  z-index: 1;
  perspective: 600px;
  box-shadow: 0 4px 6px var(--shadow);
  background-image: var(--background);
}

.loader div ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.loader div ul li {
  --r: 180deg;
  --o: 0;
  --c: var(--page);
  position: absolute;
  top: 10px;
  left: 10px;
  transform-origin: 100% 50%;
  color: var(--c);
  opacity: var(--o);
  transform: rotateY(var(--r));
  -webkit-animation: var(--duration) ease infinite;
  animation: var(--duration) ease infinite;
}

.loader div ul li:nth-child(2) {
  --c: var(--page-fold);
  -webkit-animation-name: page-2;
  animation-name: page-2;
}

.loader div ul li:nth-child(3) {
  --c: var(--page-fold);
  -webkit-animation-name: page-3;
  animation-name: page-3;
}

.loader div ul li:nth-child(4) {
  --c: var(--page-fold);
  -webkit-animation-name: page-4;
  animation-name: page-4;
}

.loader div ul li:nth-child(5) {
  --c: var(--page-fold);
  -webkit-animation-name: page-5;
  animation-name: page-5;
}

.loader div ul li svg {
  width: 90px;
  height: 120px;
  display: block;
}

.loader div ul li:first-child {
  --r: 0deg;
  --o: 1;
}

.loader div ul li:last-child {
  --o: 1;
}

.loader span {
  display: block;
  left: 0;
  right: 0;
  top: 100%;
  margin-top: 20px;
  text-align: center;
  color: var(--text);
}

@keyframes page-2 {
  0% {
    transform: rotateY(180deg);
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  35%,
  100% {
    opacity: 0;
  }

  50%,
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes page-3 {
  15% {
    transform: rotateY(180deg);
    opacity: 0;
  }

  35% {
    opacity: 1;
  }

  50%,
  100% {
    opacity: 0;
  }

  65%,
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes page-4 {
  30% {
    transform: rotateY(180deg);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  65%,
  100% {
    opacity: 0;
  }

  80%,
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes page-5 {
  45% {
    transform: rotateY(180deg);
    opacity: 0;
  }

  65% {
    opacity: 1;
  }

  80%,
  100% {
    opacity: 0;
  }

  95%,
  100% {
    transform: rotateY(0deg);
  }
}

/* End Loader  */

figure .image {
  width: 100%;
}

.univesity_title h3 a {
  color: black !important;
}

.uni_subtitle span {
  color: black !important;
}

.text-center {
  text-align: center;
}